*
{
    margin: 0;
    padding: 0;

}

html,
body
{
    height: 100vh;
    font-family: 'Poppins';
    /* background: rgb(43, 43, 43); */
    background-image: url('./sideway.jpg');
    
}

body {
    overflow-x: hidden;
}

.webgl
{
    position: absolute;
    top: 0;
    left: 0;
    outline: none;
    /* mix-blend-mode: exclusion; */
    display: block;

}

.container {
    height: 100vh;
    display: grid;
    text-align: center;
    place-items: center;
    background-color: rgba(0,0,0,0.5);
}

h1 {
    text-transform: uppercase;
    color: white;
}

section {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    place-items: center;
    margin-bottom: 10%;
    background-color: rgba(0,0,0,0.5);
}